<template>
  <div class="config-app">
    <h2 class="app-title">Definições do Robô</h2>
<!--    <p class="subtitle"></p>-->

    <div class="m-t-lg">
      <u-btn @click="forceBackground"
             :label="!backgroundEnabled ? 'Forçar execução do Robô Leiloeiro' : 'Parar exeução forçada do Robô'"
             no-caps color="green" />
    </div>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {background} from "@/domain/stats/service"

export default {
  name: "ConfigRobo",
  inject: ['config'],
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico',
      backgroundEnabled: false
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        }
      ]
    }
  },
  methods: {
    forceBackground () {
      if (this.backgroundEnabled && this.$intervalBackground) {
        this.backgroundEnabled = false
        clearInterval(this.$intervalBackground)
        return
      }
      this.backgroundEnabled = true
      if (!this.$intervalBackground) {
        this.backgroundServices()
      }
      this.$intervalBackground = setInterval(() => {
        this.backgroundServices()
      }, 30000)
    },
    backgroundServices () {
      //return;
      console.log('Execute background services...')
      background().then(r => {
        console.log(r.data)
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
