<template>
  <div class="config-app">
    <h2 class="app-title">Bens</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de gestão de bens.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper style="width: 100% !important; flex: 1" class="m-t" label="Permitir Venda Direta">
          <div class="m-t-xs"><u-checkbox v-model="config.c('bem.permitirVendaDireta', true, 'user').value" /> Sim, permitir</div>
        </app-label-wrapper>
        <app-label-wrapper label="Notificar Proposta de venda direta no email">
          <u-input placeholder="Digite o e-mail ou deixe em branco para desativar" class="app-input" hide-underline v-model="c('bem.emailNotificaPropostas').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Gestão dos Bens">
          <u-select :options="[{label: 'Padrão Leiloeiro', value: '0'},{label: 'Gestão Avançada', value: '1'}]" class="app-input" hide-underline v-model="c('bem.tipoGestao').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Forçar validação de placa e chassi no cadastro de veículo">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim, tornar obrigatório o preenchimento de pelo menos um dos campos', value: '1'}]" class="app-input" hide-underline v-model="c('bem.forcaValidacaoPlacaChassi').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Remover por padrão HTML na descrição e observação do bem">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim, ativar somente texto por padrão', value: '1'}]" class="app-input" hide-underline v-model="c('bem.descricaoEditorHtml').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Coordenadas iniciais no localizador de imóveis">
          <collapse title="Editar coordenadas" :collapse="false">
            <code-editor v-model="c('bem.mapaCoordenadasIniciais').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>

        <app-label-wrapper label="">
        </app-label-wrapper>

        <app-label-wrapper label="Remover bem da vaga no pátio após fechamento do leilão">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('bem.removerDoPatioFechamentoLeilao').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Definir data de saída do bem após fechamento do leilão">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('bem.definirDataSaidaFechamentoLeilao').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Definir data de saída do bem após mudança de status de No Pátio para outro status">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('bem.definirDataSaidaStatusBem').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configurações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>
      </div>
      <list v-if="active === 'campos'" class="app-input-container" />
      <list-campo-secao-bem v-if="active === 'camposSecao'" class="app-input-container" />
      <list-eventos-bem v-if="active === 'eventos'" class="app-input-container" />
      <list-checklist-secao-bem v-if="active === 'checklistSecao'" class="app-input-container" />
      <bem-config-checklist v-if="active === 'checklist'" class="app-input-container" />
      <bem-config-tarefas v-if="active === 'tarefas'" class="app-input-container" />
      <div v-if="active === 'vistoria'" class="app-input-container">
        <div class="m-t m-b">
          <h6 class="subtitle">Aplicativo</h6>
        </div>
        <app-label-wrapper label="Destino das fotos da vistoria">
          <u-select :options="tiposArquivos" class="app-input" hide-underline v-model="c('bem.vistoriaFotosTipoArquivoDestino').value" />
        </app-label-wrapper>
        <app-label-wrapper label="Salvar Laudo em PDF no cadastro do Bem ao terminar a vistoria">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('bem.vistoriaSalvarLaudoBem').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configuações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>
      </div>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import List from "@/components/cadastro/components/campobem/List"
import {USelect, UCheckbox, UInput} from "uloc-vue"
import BemConfigChecklist from "@/components/globalconfig/components/apps/bem/checklist/Checklist"
import BemConfigTarefas from "@/components/globalconfig/components/apps/bem/tarefas/Checklist"
import ListEventosBem from "@/components/cadastro/components/eventosbem/List"
import ListCampoSecaoBem from "@/components/cadastro/components/camposecaobem/List"
import ListChecklistSecaoBem from "@/components/cadastro/components/checklistsecaobem/List"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'
import {listTiposArquivo as list} from "@/domain/bem/services"

export default {
  name: "ConfigTarefas",
  inject: ['config'],
  components: {
    ListChecklistSecaoBem,
    ListCampoSecaoBem,
    ListEventosBem,
    BemConfigTarefas,
    BemConfigChecklist,
    List,
    UInput,
    USelect,
    UCheckbox,
    CodeEditor,
    Collapse
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico',
      tiposArquivos: []
    }
  },
  mounted() {
    this.loadTiposArquivos()
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'eventos',
          label: 'Eventos'
        },
        {
          name: 'camposSecao',
          label: 'Seção de Campos'
        },
        {
          name: 'campos',
          label: 'Campos'
        },
        {
          name: 'checklistSecao',
          label: 'Seção de Checklists'
        },
        {
          name: 'checklist',
          label: 'Checklist automático'
        },
        {
          name: 'tarefas',
          label: 'Tarefas / Providências'
        },
        {
          name: 'vistoria',
          label: 'Vistoria'
        }
      ]
    }
  },
  methods: {
    loadTiposArquivos () {
      this.loading = true
      list()
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: obj.nome,
                value: String(obj.id)
              }
            })

            !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Padrão do sistema', value: '0'})
            this.tiposArquivos = list
          })
          .catch((error) => {
            this.loading = false

          })
    }
  }
}
</script>
