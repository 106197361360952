<template>
  <div class="config-app">
    <h2 class="app-title">Configurações da Empresa</h2>
    <p class="subtitle">Permite você configurar as preferências da empresa e informações básicas como logomarca, site
      principal, entre outros.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'empresa'" class="app-input-container">
        <div class="app-input-row">
        <app-label>Logomarca da empresa / leiloeiro</app-label>
        <app-label-content>
          <u-input class="app-input" hide-underline v-model="c('empresa.logomarca').value" />
          <p class="app-legend m-t-xs m-b-none">A logomarca será utilizada em todo o sistema, e-mails, templates, documentos e etc.</p>
          <p class="app-legend m-t-sm">Se você não tiver a url da logomarca, <a>clique aqui</a> para fazer o <a>upload</a>.</p> <!-- @TODO: <RAPHAEL> Fazer upload baseado no arquivo avulso em PessoaController -->
        </app-label-content>
        </div>
        <app-label-wrapper label="URL Favicon">
          <u-input class="app-input" hide-underline v-model="c('empresa.favicon').value"/>
        </app-label-wrapper>
        <app-label-wrapper label="">
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
        </div>
        </app-label-wrapper>
      </div>
      <div v-if="active === 'website'" class="app-input-container">

        <app-label-wrapper label="Domínio principal">
          <u-input class="app-input" hide-underline v-model="c('site.dominio.principal').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Url do site">
          <u-input class="app-input" hide-underline v-model="c('site.url').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Url do painel do cliente">
          <u-input class="app-input" hide-underline v-model="c('cliente.painelUrl').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Url do painel do comitente">
          <u-input class="app-input" hide-underline v-model="c('comitente.url').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="Url Webhook Primário">
          <u-input class="app-input" hide-underline v-model="c('webhook.site').value"/>
        </app-label-wrapper>

        <app-label-wrapper label="API Token">
          <u-input class="app-input" hide-underline v-model="c('webhook.site.token').value"/>
          <p class="app-legend m-t-xs">Token do webhook para transmissão dos dados ente ERP > Site</p>
        </app-label-wrapper>

        <app-label-wrapper class="m-t" label="Google Maps API Key">
          <u-input class="app-input" hide-underline v-model="c('maps.apiKey').value"/>
          <p class="app-legend m-t-xs">Para uso no módulo de remoção e localizador de imóveis</p>
        </app-label-wrapper>

        <app-label-wrapper>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-wrapper>
      </div>
      <div v-if="active === 'email'" class="app-input-container">
        <p class="app-legend">O sistema precisa de configurações SMTP para envio de e-mails, informe-as abaixo.</p>
        <app-label-wrapper label="Remetente de envio padrão">
          <u-input class="app-input" hide-underline v-model="c('email.default').value" type="email"/>
        </app-label-wrapper>

        <app-label-wrapper label="Nome do remetente">
          <u-input class="app-input" hide-underline v-model="c('email.name.default').value" type="email"/>
        </app-label-wrapper>

        <app-label-wrapper>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-wrapper>
      </div>
      <div v-if="active === 'avancado'" class="app-input-container">
        <app-label-wrapper label="Folha de Estilo CSS do ERP">
          <collapse title="Editar folha de estilo" :collapse="false">
            <code-editor v-model="c('erp.login.css').value" :languages="[['javascript', 'css']]" :display_language="false" class="source-code" width="100%" height="600px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>

        <app-label-wrapper>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-wrapper>
      </div>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import CodeEditor from "simple-code-editor"
import Collapse from "components/globalconfig/components/include/Collapse"

export default {
  name: "Empresa",
  mixins: [AppMixin],
  components: {
    CodeEditor,
    Collapse
  },
  data() {
    return {
      active: 'empresa'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'empresa',
          label: 'Empresa'
        },
        {
          name: 'website',
          label: 'Website'
        },
        {
          name: 'email',
          label: 'Configuração de e-mail'
        },
        {
          name: 'avancado',
          label: 'Avançado'
        }
      ]
    }
  }
}
</script>
