<template>
  <div class="config-app">
    <h2 class="app-title">Marketing</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de marketing.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">

        <!--<app-label-wrapper label="Remetente padrão">
          <u-select :options="[{label: 'Padrão Leiloeiro', value: '0'},{label: 'Gestão Avançada', value: '1'}]" class="app-input" hide-underline v-model="c('bem.tipoGestao').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configurações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>-->

      </div>
      <list-remetentes v-if="active === 'remetentes'" class="app-input-container" />
      <div v-if="active === 'facebook'" class="app-input-container">

        <app-label-wrapper label="Pixel Facebook">
          <u-select :options="[{label: 'Habilitado', value: '1'},{label: 'Desativado', value: '0'}]" class="app-input" hide-underline v-model="c('mkt.facebook.pixel').value" />
          <p class="app-legend m-t-xs m-b-none">Se habilitado, o painel e aplicativo do arrematante passa a usar o pixel do facebook e acionar os eventos programados.</p>
        </app-label-wrapper>
        <app-label-wrapper label="Pixel ID">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.id').value" />
        </app-label-wrapper>

        <app-label-wrapper label="" class="m-t">
          <p>Definição dos eventos. Deixe em branco ou "0" para desativar o evento</p>
        </app-label-wrapper>
        <app-label-wrapper label="Visualização de Página">
          <u-input class="app-input" hide-underline disable v-model="tmp.fcbPageView" />
        </app-label-wrapper>
        <app-label-wrapper label="Visualização de um Bem/Lote">
          <u-input class="app-input" hide-underline disable v-model="tmp.fcbViewContent" />
        </app-label-wrapper>

        <app-label-wrapper label="Início do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.inicioCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Conclusão do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.terminoCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Envio dos Documentos">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.envioDocs').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Aprovação do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.aprovacaoCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Habilitação em Leilão">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.habilitacao').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Lance">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.lance').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Arrematação Realizada">
          <u-input class="app-input" hide-underline v-model="c('mkt.facebook.pixel.event.arremate').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configuações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>

      </div>
      <div v-if="active === 'google'" class="app-input-container">

        <app-label-wrapper label="Google Tag Manager">
          <u-select :options="[{label: 'Habilitado', value: '0'},{label: 'Desativado', value: '1'}]" class="app-input" hide-underline v-model="c('mkt.google.tag').value" />
          <p class="app-legend m-t-xs m-b-none">Se habilitado, o painel e aplicativo do arrematante passa a usar o pixel do google.tagonar os eventos programados.</p>
        </app-label-wrapper>
        <app-label-wrapper label="Tag ID">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.id').value" />
        </app-label-wrapper>

        <app-label-wrapper label="" class="m-t">
          <p>Definição dos eventos. Deixe em branco ou "0" para desativar o evento</p>
        </app-label-wrapper>
        <app-label-wrapper label="Visualização de Página">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.pageView').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Início do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.inicioCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Conclusão do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.terminoCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Envio dos Documentos">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.envioDocs').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Aprovação do Cadastro">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.aprovacaoCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Habilitação em Leilão">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.habilitacao').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Lance">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.lance').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Arrematação Realizada">
          <u-input class="app-input" hide-underline v-model="c('mkt.google.tag.event.arremate').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configuações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>

      </div>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import ListRemetentes from "@/components/cadastro/components/mktremetentes/List"

export default {
  name: "ConfigTarefas",
  inject: ['config'],
  components: {
    ListRemetentes,
    USelect
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico',
      tmp: {
        fcbPageView: 'PageView',
        fcbViewContent: 'ViewContent',
      }
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'remetentes',
          label: 'Remetentes'
        },
        {
          name: 'facebook',
          label: 'Facebook / Meta Ads'
        },
        {
          name: 'google',
          label: 'Google Analytics'
        }
      ]
    }
  }
}
</script>
